import { EventEmitter, Injectable } from '@angular/core';
import { isInIframe } from '@app/helpers/iframe-helper';
import { getIconByCatalogViewTreeItemType } from '@commonHelpers/catalog-tree-icon-provider';
import { delayHelper } from '@commonHelpers/delay-helper';
import { isNullish } from '@commonHelpers/math-utils';
import { ConfirmDialogService } from '@commonServices/confirm-dialog.service';
import { WatchlistMergeDialogType } from '@enums/watchlist-merge-dialog-type';
import { CatalogViewTreeItemType, ExportSettingsDto, WatchListCheckDto, WatchListDto, WatchListStructure } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { INode } from '@interfaces/iNode';
import { IWatchlist, IWatchlistDragStart } from '@interfaces/iWatchlist';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { AccountService } from './account.service';
import { ALERT_DISPLAY_TYPES, ALERT_POSITION_TYPES, AlertService } from './alert.service';
import { GlobalVarService } from './global-var.service';
import { TranslationService } from './translation.service';
import { WatchlistService } from './watchlist.service';

@Injectable({
	providedIn: 'root'
})
export class WatchlistActionService {
	constructor(private watchlistService: WatchlistService,
		private globalVarService: GlobalVarService,
		private alertService: AlertService,
		private translationService: TranslationService,
		private confirmDialogService: ConfirmDialogService,/*  */
		private accountService: AccountService) { }

	private static watchlistChanged = new EventEmitter();
	private static deleteUserDefinedList = new EventEmitter();
	private static watchlistItemsChanged = new EventEmitter();
	private static deleteUserDefinedItem = new EventEmitter();
	private static initWatchlist = new EventEmitter<void>();
	public nodesRequest = new EventEmitter<void>();
	public watchlistNodesRequest = new EventEmitter<void>();
	private sendAllNodes = new EventEmitter<INode[]>()
	private sendAllWatchlistNodes = new EventEmitter<INode[]>()
	public openDialog = new EventEmitter();
	private markedFolder: string[] = [];
	private partlyMarkedFolder: string[] = [];
	private static maxItems: number;
	private static endWatchlistMerge = new EventEmitter<void>();
	private static openWatchlistMergeDialog = new Subject<any>();
	private static isInit = false;
	private static triggerInit = false;
	private static exportSettings: ExportSettingsDto;
	private static warningFolderCount: number;
	private hasNotUniqueGuids: boolean;
	private watchlistStructure: WatchListStructure
	private userDefinedPositionIds: number[] = [];
	private static navigation: Window;

	public async initWatchlist() {
		if (WatchlistActionService.triggerInit || isInIframe()) {
			return
		}
		window.addEventListener('storage', this.storageChangeListener);
		WatchlistActionService.triggerInit = true;
		let watchlist = this.getWatchlist();
		if (isNullish(WatchlistActionService.maxItems)) {
			const info = await this.watchlistService.getWatchListInformation();
			if (!isNullish(info)) {
				WatchlistActionService.maxItems = info.maxWatchListItems;
				WatchlistActionService.warningFolderCount = info.warningFolderCount;
				WatchlistActionService.exportSettings = info.exportSettings;
				if (watchlist.version !== info.watchListVersion) {
					this.globalVarService.deleteWatchlist();
					watchlist = this.getWatchlist(info.watchListVersion);
					this.globalVarService.setWatchlist(watchlist);

				}
			}
		}
		if (this.accountService.isLoggedIn()) {
			await this.mergeWatchlist(watchlist);
		} else {

			if (watchlist?.isLogin) {
				watchlist.watchlistItems = [];
				watchlist.isLogin = false;
				this.globalVarService.setWatchlist(watchlist);
			}
		}
		WatchlistActionService.isInit = true;
		WatchlistActionService.initWatchlist.emit();
	}

	async initIFrameWatchlist() {
		window.addEventListener('storage', this.storageChangeListener);
		if (this.accountService.isLoggedIn()) {
			const info = await this.watchlistService.getWatchListInformation();
			const watchlistFlat = await this.watchlistService.getWatchListAsFlatList();
			const watchlist = this.getWatchlist(info.watchListVersion);
			watchlist.isLogin = true;
			watchlist.watchlistItems = watchlistFlat.catalogItemGuids;
			this.globalVarService.setWatchlist(watchlist);
			WatchlistActionService.maxItems = info.maxWatchListItems;
			WatchlistActionService.warningFolderCount = info.warningFolderCount;
			WatchlistActionService.exportSettings = info.exportSettings;
		} else {
			WatchlistActionService.maxItems = null;
		}
		WatchlistActionService.triggerInit = true;
		WatchlistActionService.isInit = true;
		WatchlistActionService.initWatchlist.emit();
		WatchlistActionService.watchlistChanged.emit();

	}

	private setWatchlistFolder(markedFolder: string[], partlyMarkedFolder: string[]) {
		this.markedFolder = markedFolder;
		this.partlyMarkedFolder = partlyMarkedFolder;
	}

	public isWatchlistUpdating() {
		return this.watchlistService.getWatchlistUpdating();
	}

	public setHasNotUniqueGuids(hasNotUniqueGuids: boolean) {
		this.hasNotUniqueGuids = hasNotUniqueGuids
	}

	public getHasNotUniqueGuids() {
		return this.hasNotUniqueGuids;
	}

	public navigateToWatchlist(url: string) {
		// Prüfung auf window prüft ob Fenster geschlossen wurde, pathname prüft ob innerhalb der Merkliste wegnavigiert wurde, Zusätzliche prüfung (wegen Chromebug) auf geschlossenen Tab auf innerheight = 0
		if (isNullish(WatchlistActionService.navigation?.window) || !WatchlistActionService.navigation?.window?.location?.pathname?.startsWith('/watchlist') || WatchlistActionService.navigation?.innerHeight === 0) {
			WatchlistActionService.navigation = window.open(url.toString(), '_blank');
		} else {
			WatchlistActionService.navigation.focus();
		}
	}





	public async watchListToggle(toggle: ToggleWatchlistDto) {
		const allNodes = toggle.isFolder ? isNullish(toggle.watchlistNodes) ? await this.getAllNodes() : toggle.watchlistNodes : [];
		const watchlist = this.getWatchlist();
		if (this.watchlistService.getWatchlistUpdating()) {
			return;
		}
		// Element Löschen
		if (!toggle.allwaysAdd && (toggle.allwaysDelete || watchlist.watchlistItems.some(item => item === toggle.catalogItemGuid) || this.markedFolder.includes(toggle.catalogItemGuid) || this.partlyMarkedFolder.includes(toggle.catalogItemGuid))) {
			if (this.accountService.isLoggedIn()) {
				this.watchlistService.setWatchlistUpdating(true);
				const isInUserDefined = await this.watchListCheck(toggle.catalogItemGuid)
				if (isInUserDefined.existsInUserDefinedList || toggle.showDialogAllwayInDelete) {
					this.watchlistService.setWatchlistUpdating(false);
					this.confirmDialogService.open({
						description: 'watchlist.deleteConfirm.description',
						title: 'watchlist.deleteConfirm.title',
						reject: 'global.cancel',
						confirm: 'global.delete',
						descriptionVars: {
							name: isInUserDefined.catalogItemTitle,
							aditionalText: isInUserDefined.existsInUserDefinedList ?
								this.translationService.getByKey(`watchlist.deleteConfirm.descriptionAdditionalUserdefined${toggle.isFolder ? 'Folder' : ''}`, {
									positionText: !toggle.isFolder ? '' :
										this.translationService.getByKey(`watchlist.deleteConfirm.positionText.${isInUserDefined.catalogItemCount > 1 ? 'plural' : 'singular'}`, { count: isInUserDefined.catalogItemCount }),
									type: this.translationService.getByKey(`watchlist.deleteConfirm.type.folder`)
								}) : ''
						}
					}, async () => this.deleteItem(toggle.catalogItemGuid, watchlist, toggle.positionType))
				} else {
					await this.deleteItem(toggle.catalogItemGuid, watchlist, toggle.positionType)
				}
			} else {
				if (toggle.showDialogAllwayInDelete) {
					this.confirmDialogService.open({
						description: 'watchlist.deleteConfirm.description',
						title: 'watchlist.deleteConfirm.title',
						reject: 'global.cancel',
						confirm: 'global.delete',
						descriptionVars: { name: toggle.text, aditionalText: '' }
					}, async () => {
						if (toggle.isFolder) {
							this.handleCatalogTreeFolderNotLogin(allNodes, watchlist, toggle, true);
						} else {
							const idx = watchlist.watchlistItems.findIndex(item => item === toggle.catalogItemGuid);
							watchlist.watchlistItems.splice(idx, 1);
							this.showWatchlistHandleMessage(true, toggle.positionType);
						}
						this.globalVarService.setWatchlist(watchlist);
						WatchlistActionService.watchlistChanged.emit();
					})
				} else {
					if (toggle.isFolder) {
						this.handleCatalogTreeFolderNotLogin(allNodes, watchlist, toggle, true);
					} else {
						const idx = watchlist.watchlistItems.findIndex(item => item === toggle.catalogItemGuid);
						watchlist.watchlistItems.splice(idx, 1);
						this.showWatchlistHandleMessage(true, toggle.positionType);
					}
				}

			}

			// Prüfung zu viele Elemente
		} else if (this.handleCountItems(watchlist, allNodes, toggle)) {
			await this.handleAddItems(watchlist, allNodes, toggle)
		}
		this.globalVarService.setWatchlist(watchlist);
		WatchlistActionService.watchlistChanged.emit();

	}

	public watchListDragStart(guid: string, isFolder: boolean, allNodes?: INode[]) {
		const watchlistItems = this.getWatchlist().watchlistItems;
		let folderPositionGuids = null;
		if (!isNullish(allNodes)) {
			const allItemsToAdd = this.getPositionsOfFolder(allNodes, guid);
			folderPositionGuids = allItemsToAdd.filter(item => !watchlistItems.includes(item))
		}
		if (!isFolder && watchlistItems.includes(guid) || isFolder && (isNullish(folderPositionGuids) || folderPositionGuids.length === 0)) {
			this.globalVarService.deleteWatchlistItemDragStart();
		} else {
			this.globalVarService.setWatchlistItemDragStart({
				guid,
				isFolder,
				folderPositionGuids
			})
		}

	}

	public getWatchlistDragStartData(): IWatchlistDragStart {
		return this.globalVarService.getWatchlistItemDragStart()
	}

	private async handleAddItems(watchlist: IWatchlist, allNodes: INode[], toggle: ToggleWatchlistDto) {
		if (this.accountService.isLoggedIn()) {
			const flatlist = await this.watchlistService.addCatalogItemToWatchList(toggle.catalogItemGuid);
			if (!isNullish(flatlist)) {
				if (flatlist.tooManyItems) {
					this.showTooManyItemsDialog();
				} else {
					watchlist.watchlistItems = flatlist.catalogItemGuids;
					this.showWatchlistHandleMessage(false, toggle.positionType);
				}
			}

		} else {
			if (toggle.isFolder) {
				this.handleCatalogTreeFolderNotLogin(allNodes, watchlist, toggle, false);
			} else {
				if ((watchlist.watchlistItems.length + 1) > WatchlistActionService.maxItems) {
					this.showTooManyItemsDialog();
				} else {
					watchlist.watchlistItems.push(toggle.catalogItemGuid);
					this.showWatchlistHandleMessage(false, toggle.positionType);
				}

			}
		}
	}

	public async removeCatalogFromWatchlist(catalogId: number, positionType: ALERT_POSITION_TYPES, text: string, watchlistNodes: INode[]) {
		if (this.accountService.isLoggedIn()) {
			this.watchlistService.setWatchlistUpdating(true);
			const isInUserDefined = await this.watchlistService.checkForRemoveIfCatalogItemsAreInUserDefinedListByCatalogId(catalogId)
			this.watchlistService.setWatchlistUpdating(false);
			this.confirmDialogService.open({
				description: 'watchlist.deleteConfirm.description',
				title: 'watchlist.deleteConfirm.title',
				reject: 'global.cancel',
				confirm: 'global.delete',
				descriptionVars: {
					name: isInUserDefined.catalogItemTitle,
					aditionalText: isInUserDefined.existsInUserDefinedList ?
						this.translationService.getByKey(`watchlist.deleteConfirm.descriptionAdditionalUserdefinedFolder`, {
							positionText:
								this.translationService.getByKey(`watchlist.deleteConfirm.positionText.${isInUserDefined.catalogItemCount > 1 ? 'plural' : 'singular'}`, { count: isInUserDefined.catalogItemCount }),
							type: this.translationService.getByKey(`watchlist.deleteConfirm.type.catalog`)
						}) : ''
				}
			}, async () => this.deleteCatalogItem(catalogId, positionType))

		} else {
			this.confirmDialogService.open({
				description: 'watchlist.deleteConfirm.description',
				title: 'watchlist.deleteConfirm.title',
				reject: 'global.cancel',
				confirm: 'global.delete',
				descriptionVars: {
					name: text,
					aditionalText: ''
				}
			}, () => this.handleCatalogTreeCatalogNotLogin(catalogId, positionType, watchlistNodes));
		}
	}

	private async getAllNodes(): Promise<INode[]> {
		this.nodesRequest.emit();
		const endTrigger = new EventEmitter();
		let resultNodes;
		this.sendAllNodes.subscribe(nodes => {
			resultNodes = nodes;
			endTrigger.next(null);
			endTrigger.complete()
		});
		await endTrigger.toPromise()
		return resultNodes
	}

	public sendNodes(allNodes: INode[]) {
		delayHelper(() => {
			this.sendAllNodes.emit(allNodes)
		})
	}

	private async getAllWatchlistNodes(): Promise<INode[]> {
		this.watchlistNodesRequest.emit();
		const endTrigger = new EventEmitter();
		let resultNodes;
		this.sendAllWatchlistNodes.subscribe(nodes => {
			resultNodes = nodes;
			endTrigger.next(null);
			endTrigger.complete()
		});
		await firstValueFrom(endTrigger);
		return resultNodes
	}

	public sendWatchlistNodes(allNodes: INode[]) {
		delayHelper(() => {
			this.sendAllWatchlistNodes.emit(allNodes)
		})
	}



	private async deleteCatalogItem(catalogId: number, positionType: ALERT_POSITION_TYPES) {
		const flatlist = await this.watchlistService.removeCatalogItemsFromWatchListByCatalogId(catalogId);
		if (!isNullish(flatlist)) {
			const watchlist = this.getWatchlist()
			watchlist.watchlistItems = flatlist.catalogItemGuids;
			this.globalVarService.setWatchlist(watchlist);
			this.showWatchlistHandleMessage(true, positionType);
		}
		WatchlistActionService.watchlistChanged.emit();

	}


	private async deleteItem(catalogItemGuid: string, watchlist: IWatchlist, positionType: ALERT_POSITION_TYPES) {
		const flatlist = await this.watchlistService.removeCatalogItemFromWatchList(catalogItemGuid);
		if (!isNullish(flatlist)) {
			watchlist.watchlistItems = flatlist.catalogItemGuids;
			this.globalVarService.setWatchlist(watchlist);
			this.showWatchlistHandleMessage(true, positionType);
		}
		WatchlistActionService.watchlistChanged.emit();

	}

	public async watchListCheck(catalogItemGuid): Promise<WatchListCheckDto> {
		if (this.accountService.isLoggedIn()) {
			return this.watchlistService.checkForRemoveIfCatalogItemIsInUserDefinedList(catalogItemGuid);
		}
		return { existsInUserDefinedList: false, catalogItemCount: 0 };
	}

	public updateCatalogTree(allNodes: INode[]) {
		const hasTooltip = !('ontouchstart' in document.documentElement);
		const items = this.getWatchlist().watchlistItems;
		const parents = allNodes.filter(node => this.isFolderType(node.type));
		const children = allNodes.filter(node => !this.isFolderType(node.type));
		const watchlistNodes = [];
		const notWatchlistNodes = []

		children.forEach(node => {
			node.hasWatchlistIcon = true;
			node.isInWatchlist = items.includes(node.guid);
			node.watchlistIconClass = this.getWatchlistIconClass(node.isInWatchlist);
			node.watchlistTooltip = hasTooltip ? 'catalog-view.detail-actions.watchlist.' + (node.isInWatchlist ? 'remove' : 'add') : undefined;
			node.icon = getIconByCatalogViewTreeItemType(node.type as CatalogViewTreeItemType, node.isInWatchlist)
			if (node.isInWatchlist) {
				watchlistNodes.push(node)
			} else {
				notWatchlistNodes.push(node)
			}
		});

		const watchlistItemsParentsMarked = [];
		const watchlistItemsParentsPartlyMarked = [];

		parents.forEach(node => {
			const hasOneInWatchlist = watchlistNodes.some(item => item.ancestors.includes(node.id));
			const hasOneNotInWatchlist = notWatchlistNodes.some(item => item.ancestors.includes(node.id));

			const isInWatchlist = hasOneInWatchlist && !hasOneNotInWatchlist;
			const isPartlyInWatchlist = hasOneInWatchlist && hasOneNotInWatchlist;
			if (isInWatchlist) {
				watchlistItemsParentsMarked.push(node.guid);
			}
			if (isPartlyInWatchlist) {
				watchlistItemsParentsPartlyMarked.push(node.guid);
			}
			node.hasWatchlistIcon = true;
			node.isInWatchlist = isInWatchlist;
			node.isPartlyInWatchlist = isPartlyInWatchlist;
			node.icon = getIconByCatalogViewTreeItemType(node.type as CatalogViewTreeItemType, isInWatchlist, isPartlyInWatchlist);
			node.watchlistIconClass = this.getWatchlistIconClass(isInWatchlist || isPartlyInWatchlist);
			node.watchlistTooltip = 'catalog-view.detail-actions.watchlist.' + (isInWatchlist || isPartlyInWatchlist ? 'remove' : 'add');
		})

		this.setWatchlistFolder(watchlistItemsParentsMarked, watchlistItemsParentsPartlyMarked);
		this.sendAllNodes.emit(allNodes)
	}

	private handleCatalogTreeFolderNotLogin(allNodes: INode[], watchlist: IWatchlist, toggle: ToggleWatchlistDto, remove: boolean) {
		const children = isNullish(toggle.positionsToAdd) ?
			this.getPositionsOfFolder(allNodes, toggle.catalogItemGuid) :
			toggle.positionsToAdd;
		watchlist.watchlistItems = remove ? watchlist.watchlistItems.filter(item => !children.includes(item)) : watchlist.watchlistItems.concat(children);
		this.showWatchlistHandleMessage(remove, toggle.positionType);
	}

	private handleCountItems(watchlist: IWatchlist, allNodes: INode[], toggle: ToggleWatchlistDto) {
		const children = isNullish(toggle.positionsToAdd) ?
			this.getPositionsOfFolder(allNodes, toggle.catalogItemGuid) :
			toggle.positionsToAdd;
		const items = watchlist.watchlistItems.concat(children);
		if (items.length > WatchlistActionService.maxItems) {
			this.showTooManyItemsDialog();
			return false;
		} else if (items.length - watchlist.watchlistItems.length > WatchlistActionService.warningFolderCount) {
			this.showWarningFolderCountDialog(items.length - watchlist.watchlistItems.length, watchlist, allNodes, toggle);
			return false;
		}
		return true;
	}

	private getPositionsOfFolder(allNodes: INode[], catalogItemFolderGuid: string) {
		const parent = allNodes.find(node => node.guid === catalogItemFolderGuid);
		return allNodes?.filter(node => node.ancestors?.includes(parent?.id) && !this.isFolderType(node?.type))?.map(node => node?.guid);
	}

	private handleCatalogTreeCatalogNotLogin(catalogId: number, positionType: ALERT_POSITION_TYPES, allNodes: INode[]) {
		const parentNode = allNodes?.find(node => node.id === -catalogId);
		const nodes = allNodes.filter(node => node.watchlistCatalogKey !== parentNode.watchlistCatalogKey);
		if (!isNullish(nodes)) {
			const watchlist = this.getWatchlist();
			watchlist.watchlistItems = nodes.filter(node => !isNullish(node.guid)).map(node => node.guid);
			this.globalVarService.setWatchlist(watchlist);
			this.showWatchlistHandleMessage(true, positionType);
		}
		WatchlistActionService.watchlistChanged.emit();
	}

	private isFolderType(type: CatalogViewTreeItemType) {
		return type === CatalogViewTreeItemType.Folder || type === CatalogViewTreeItemType.FolderWithAttachments;
	}

	private getWatchlistIconClass(isInWatchlist: boolean): string {
		return isInWatchlist ? 'remove-from-watchlist-img' : 'add-to-watchlist-img'
	}

	private showTooManyItemsDialog() {
		if (this.accountService.isLoggedIn()) {
			this.confirmDialogService.open({
				description: 'catalog-view.detail-actions.watchlist.too-much.description',
				title: 'catalog-view.detail-actions.watchlist.too-much.title',
				reject: undefined,
				confirm: 'global.ok',
				descriptionVars: { count: WatchlistActionService.maxItems }
			}, () => { })
		} else {
			this.confirmDialogService.open({
				description: `catalog-view.detail-actions.watchlist.too-much-login.${isInIframe() ? 'description-iframe' : 'description'}`,
				title: 'catalog-view.detail-actions.watchlist.too-much-login.title',
				reject: 'global.cancel',
				confirm: 'global.login-registration',
				descriptionVars: { count: WatchlistActionService.maxItems }
			}, async () => this.accountService.login())
		}

	}

	private showWarningFolderCountDialog(folderItems: number, watchlist: IWatchlist, allNodes: INode[], toggle: ToggleWatchlistDto) {
		const tags = { openTag: '<span class="fw-bold">', closeTag: '</span>' }
		this.confirmDialogService.open({
			description: 'catalog-view.detail-actions.watchlist.warningFolderConfirm.description',
			title: 'catalog-view.detail-actions.watchlist.warningFolderConfirm.title',
			reject: 'global.cancel',
			confirm: 'global.ok',
			descriptionVars: { count: folderItems, ...tags }
		}, async () => {
			await this.handleAddItems(watchlist, allNodes, toggle);
			this.globalVarService.setWatchlist(watchlist);
			WatchlistActionService.watchlistChanged.emit();
		})
	}

	private showWatchlistHandleMessage(remove: boolean, positionType) {
		if (remove) {
			this.alertService.sendMessage(positionType, ALERT_DISPLAY_TYPES.DANGER, this.translationService.getByKey("catalog-view.detail-actions.watchlist.removed"))
		} else {
			this.alertService.sendMessage(positionType, ALERT_DISPLAY_TYPES.SUCCESS, this.translationService.getByKey("catalog-view.detail-actions.watchlist.added"))
		}
	}

	private storageChangeListener = () => {
		WatchlistActionService.watchlistChanged.emit();
	}

	public getMaxCount() {
		return WatchlistActionService.maxItems;
	}

	public getWatchlistStructure(): WatchListStructure {
		return this.watchlistStructure;
	}

	public setWatchlistStructure(structure: WatchListStructure) {
		this.watchlistStructure = structure;
	}

	public getExportSettings() {
		return WatchlistActionService.exportSettings;
	}

	public async clearUserDefinedList() {
		WatchlistActionService.deleteUserDefinedList.emit();
	}

	public async clearList() {
		let newList = []
		if (this.accountService.isLoggedIn()) {
			const flatList = await this.watchlistService.removeAllCatalogItemsFromWatchList();
			newList = flatList.catalogItemGuids;
		}

		const watchlist = this.globalVarService.getWatchlist();
		const emptyWatchlicht: IWatchlist = {
			version: watchlist.version,
			isLogin: this.accountService.isLoggedIn(),
			watchlistItems: newList
		}
		this.globalVarService.setWatchlist(emptyWatchlicht);
		WatchlistActionService.watchlistChanged.emit();
	}

	public getWatchlist(version?: string) {
		let watchlist = this.globalVarService.getWatchlist();
		watchlist ??= {
			watchlistItems: [],
			isLogin: this.accountService.isLoggedIn(),
			version
		};

		return watchlist;
	}

	public setuserDefinedPositionIds(positionIds: number[]) {
		this.userDefinedPositionIds = positionIds;
	}

	public getuserDefinedPositionIds(): number[] {
		return this.userDefinedPositionIds;
	}

	private async mergeWatchlist(currentWatchlist: IWatchlist) {
		const watchlist = !currentWatchlist?.isLogin ? await this.watchlistService.addPositionsToWatchList(currentWatchlist.watchlistItems) : await this.watchlistService.getWatchListAsFlatList();
		if (!isNullish(watchlist)) {
			if (watchlist.tooManyItems) {
				await this.openMergeDialog(watchlist.currentWatchListItemsCount, this.getWatchlist().watchlistItems.length, this.getMaxCount());
			} else {
				currentWatchlist.watchlistItems = watchlist.catalogItemGuids;
				currentWatchlist.isLogin = true;
				this.globalVarService.setWatchlist(currentWatchlist);
			}
		}

	}

	public async mergeWatchlistByType(currentSelection: WatchlistMergeDialogType) {
		const watchlist = this.getWatchlist();
		if (currentSelection === WatchlistMergeDialogType.Local) {
			await this.watchlistService.removeAllCatalogItemsFromWatchList();
			await this.watchlistService.addPositionsToWatchList(watchlist.watchlistItems);
		} else {
			const items = await this.watchlistService.getWatchListAsFlatList();
			watchlist.watchlistItems = items.catalogItemGuids;

		}
		watchlist.isMerging = false;
		this.globalVarService.setWatchlist(watchlist);
		this.completeWatchlistMerge();
	}

	public completeWatchlistMerge() {
		WatchlistActionService.endWatchlistMerge.next(null);
		WatchlistActionService.endWatchlistMerge.complete();
	}

	public isMarked(catalogItemGuid: string): ({ isMarked: boolean, isPartlyMarked: boolean }) {

		const watchlist = this.getWatchlist();
		if (watchlist) {
			return {
				isMarked: this.markedFolder.includes(catalogItemGuid) || watchlist.watchlistItems.some(item => item === catalogItemGuid),
				isPartlyMarked: this.partlyMarkedFolder.includes(catalogItemGuid)
			}
		}

		return { isMarked: false, isPartlyMarked: false };
	}



	public async getWatchListStructure(structureInfo: WatchListStructure): Promise<WatchListDto> {
		const watchlist = this.getWatchlist();
		if (this.accountService.isLoggedIn()) {
			return this.watchlistService.getWatchListStructure(structureInfo);
		} else {
			return this.watchlistService.getWatchListStructureFromEveryone(watchlist.watchlistItems, structureInfo);
		}

	}

	public removeAllUnavailableCatalogItemsFromWatchListForEveryone(availables: INode[]) {
		const watchlist = this.getWatchlist();
		watchlist.watchlistItems = watchlist.watchlistItems.filter(item => availables.some(available => available.guid === item));
		this.globalVarService.setWatchlist(watchlist);
	}

	public async removeAllUnavailableCatalogItemsFromWatchList() {
		await this.watchlistService.removeAllUnavailableCatalogItemsFromWatchList();
	}

	public triggerDeleteUserDefinedItem() {
		WatchlistActionService.deleteUserDefinedItem.emit();
	}

	public async countAllPositions() {
		const allnodes = await this.getAllWatchlistNodes();
		return allnodes?.filter(node => node.type !== CatalogViewTreeItemType.Folder && node.type !== CatalogViewTreeItemType.FolderWithAttachments)?.length;
	}

	public async getNodesByGuids(guids: string[]): Promise<INode[]> {
		const allNodes = await this.getAllWatchlistNodes();
		return allNodes.filter(node => guids.some(guid => node.guid === guid))
	}

	public async getNodesByIds(ids: number[]): Promise<INode[]> {
		const allNodes = await this.getAllWatchlistNodes();
		return allNodes.filter(node => ids.some(id => node.id === id));
	}


	private async openMergeDialog(accountCount: number, localCount: number, maxCount: number) {
		const watchlist = this.getWatchlist();
		watchlist.isMerging = true;
		this.globalVarService.setWatchlist(watchlist);
		delayHelper(() => {
			WatchlistActionService.openWatchlistMergeDialog.next(
				{
					accountCount,
					localCount,
					maxCount
				}
			);
		})


		await WatchlistActionService.endWatchlistMerge.toPromise()

	}

	public reOpenMerge(accountCount: number, localCount: number, maxCount: number) {
		WatchlistActionService.openWatchlistMergeDialog.next(
			{
				accountCount,
				localCount,
				maxCount
			}
		);
	}

	public getWatchlistMergeDialog(): Observable<any> {
		return WatchlistActionService.openWatchlistMergeDialog.asObservable();
	}

	public getDeleteWatchlistSubscription() {
		return WatchlistActionService.deleteUserDefinedList;
	}

	public getWatchlistChangedSubscription() {
		return WatchlistActionService.watchlistChanged;
	}

	public setWatchlistItemsChanged() {
		return WatchlistActionService.watchlistItemsChanged.emit();
	}

	public getWatchlistItemsChangedSubscription() {
		return WatchlistActionService.watchlistItemsChanged;
	}

	public getDeleteUserDefinedItemSubscription() {
		return WatchlistActionService.deleteUserDefinedItem;
	}

	public getInitWatchlistSubscription() {
		return WatchlistActionService.initWatchlist;
	}

	public isInit() {
		return WatchlistActionService.isInit;
	}


}

export interface ToggleWatchlistDto {
	catalogItemGuid: string,
	positionType: ALERT_POSITION_TYPES,
	isFolder?: boolean,
	showDialogAllwayInDelete?: boolean,
	allwaysDelete?: boolean,
	allwaysAdd?: boolean,
	positionsToAdd?: string[],
	text?: string,
	watchlistNodes?: INode[]
}
